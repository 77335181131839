<!--
 * @Author: your name
 * @Date: 2021-07-31 11:18:35
 * @LastEditTime: 2022-02-13 09:06:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/token.vue
-->
<!--
 * @Author: your name
 * @Date: 2021-07-31 11:18:35
 * @LastEditTime: 2021-07-31 11:18:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/token.vue
-->
<template>
  <div class="about">
    <h1>正在登录跳转....</h1>
    <!--
    <div>post:{{ data }}</div>
    <div>res: {{ resText }}</div>
    -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: "/",
      data: {},
      resText: {},
    };
  },
  created() {
    if (this.$route.query.state) {
      this.state = decodeURIComponent(this.$route.query.state);
    }
  },
  mounted() {
    this.usinfo(this.$route.query.openuid);
  },
  methods: {
    usinfo(openuid) {
      let data = {
        module: "user_info",
        title: "setcookie",
        data: {
          res: openuid,
          server: "school",
        },
      };
      this.data = data;
      let that = this;
      this.$sopen.requestApi(data).then(function (res) {
        if (res.user_info.errcode == 0 && res.user_info.errmsg) {
          that.$sopen.setCookie("uskey", JSON.stringify(res.user_info.errmsg));
          window.location.href = that.state;
        } else {
          that.$sopen.loginout();
          if (that.$sopen.isWechat() == true) {
            that.$router.push({
              path: "/weChat/authorize",
              query: {
                state: encodeURIComponent("/home"),
              },
            });
          } else {
            
            that.$router.push({
              path: "/login",
            });
          }
        }
        
      });
    },
  },
};
</script>
